import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import "./App.scss";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { db } from './firebaseConfig';
import firebase from 'firebase/compat/app';
import 'chart.js/auto';
import SpinnerProgressBar from './SpinnerProgressBar';
import { Link, useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Dropdown, DropdownButton, Modal, Button, Form } from 'react-bootstrap';
import AdditionalFeature from './components/AdditionalFeature';
import HistoricalDataAnalysis from './components/HistoricalDataAnalysis';
import AutoMode from './components/AutoMode';
import CoreWebVitalsChart from './components/CoreWebVitalsChart';


function getColorAndStatus(score) {
  if (score <= 49) {
    return { color: 'red', status: 'Poor Speed' };
  } else if (score <= 79) {
    return { color: 'orange', status: 'Needs Improvement' };
  } else {
    return { color: 'green', status: 'Good' };
  }
}

function evaluateFCP(fcp) {
  if (fcp < 1.8) return { quality: 'Good', color: 'green' };
  if (fcp >= 1.8 && fcp < 3.0) return { quality: 'Needs Improvement', color: 'orange' };
  return { quality: 'Poor', color: 'red' };
}

function evaluateLCP(lcp) {
  if (lcp < 2.5) return { quality: 'Good', color: 'green' };
  if (lcp >= 2.5 && lcp < 4.0) return { quality: 'Needs Improvement', color: 'orange' };
  return { quality: 'Poor', color: 'red' };
}

function evaluateTBT(tbt) {
  if (tbt < 200) return { quality: 'Good', color: 'green' };
  if (tbt >= 200 && tbt < 600) return { quality: 'Needs Improvement', color: 'orange' };
  return { quality: 'Poor', color: 'red' };
}

function evaluateCLS(cls) {
  if (cls < 0.1) return { quality: 'Good', color: 'green' };
  if (cls >= 0.1 && cls < 0.25) return { quality: 'Needs Improvement', color: 'orange' };
  return { quality: 'Poor', color: 'red' };
}

function evaluateSpeedIndex(speedIndex) {
  if (speedIndex < 3.4) return { quality: 'Good', color: 'green' };
  if (speedIndex >= 3.4 && speedIndex < 5.8) return { quality: 'Needs Improvement', color: 'orange' };
  return { quality: 'Poor', color: 'red' };
}


function App() {
  const [urls, setUrls] = useState('');
  const [mainUrls, setMainUrls] = useState([]);
  const [results, setResults] = useState([]);
  const [autoMode, setAutoMode] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [schedule, setSchedule] = useState(''); 
  const [scheduleType, setScheduleType] = useState('');
  const [scheduleDetails, setScheduleDetails] = useState({ dateTime: '', dayOfWeek: '', dayOfMonth: '', time: '' });
  const [scheduleMessage, setScheduleMessage] = useState('');
  const autoModeRef = useRef(autoMode);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [selectedProjectIds, setSelectedProjectIds] = useState([]);
  
    useEffect(() => {
      const unsubscribe = firebase.auth().onAuthStateChanged(setUser);
      return () => unsubscribe();
    }, []);

    const handleLogout = async () => {
      try {
        await firebase.auth().signOut();
        setUser(null);
        navigate('/');
      } catch (error) {
        console.error('Error logging out:', error);
      }
    };
    
    const fetchPageSpeedData = useCallback(async (urlList) => {
      if (!user) return;
    
      setIsFetching(true);
      try {
        const idToken = await user.getIdToken(); 
        const fetchPromises = urlList.map(url =>
          axios.post('/api/pagespeed', { url }, {
            headers: {
              'Authorization': `Bearer ${idToken}`, 
            }
          })
        );
        const responses = await Promise.all(fetchPromises);
    
        const newResults = responses.map((response, index) => {
          const mobileMetrics = response.data.mobile.metrics || {};
          const desktopMetrics = response.data.desktop.metrics || {};
          
          return {
            url: urlList[index],
            mobileSpeed: Math.round(response.data.mobile.score),
            desktopSpeed: Math.round(response.data.desktop.score),
            
            mobileMetrics: {
              fcp: mobileMetrics.firstContentfulPaint || null,
              lcp: mobileMetrics.largestContentfulPaint || null,
              tbt: mobileMetrics.totalBlockingTime || null,
              cls: mobileMetrics.cumulativeLayoutShift || null,
              speedIndex: mobileMetrics.speedIndex || null,
            },
            
            desktopMetrics: {
              fcp: desktopMetrics.firstContentfulPaint || null,
              lcp: desktopMetrics.largestContentfulPaint || null,
              tbt: desktopMetrics.totalBlockingTime || null,
              cls: desktopMetrics.cumulativeLayoutShift || null,
              speedIndex: desktopMetrics.speedIndex || null,
            },
    
            timestamp: new Date().toISOString(),
          };
        });
    
        setResults(prevResults => [...prevResults, ...newResults]);
    
        if (user) {
          for (const result of newResults) {
            await db.collection(`users/${user.uid}/pageSpeedData`).add({
              url: result.url,
              mobileSpeed: result.mobileSpeed,
              desktopSpeed: result.desktopSpeed,
              
              mobileFCP: result.mobileMetrics.fcp,
              mobileLCP: result.mobileMetrics.lcp,
              mobileTBT: result.mobileMetrics.tbt,
              mobileCLS: result.mobileMetrics.cls,
              mobileSpeedIndex: result.mobileMetrics.speedIndex,
              
              desktopFCP: result.desktopMetrics.fcp,
              desktopLCP: result.desktopMetrics.lcp,
              desktopTBT: result.desktopMetrics.tbt,
              desktopCLS: result.desktopMetrics.cls,
              desktopSpeedIndex: result.desktopMetrics.speedIndex,
    
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            });
          }
        }
      } finally {
        setIsFetching(false);
      }
    }, [user]);
      
  useEffect(() => {
    autoModeRef.current = autoMode;
  }, [autoMode]);

  useEffect(() => {
    if (user) {
      const fetchProjects = async () => {
        try {
          const snapshot = await db.collection(`users/${user.uid}/projects`).get();
          const projects = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setMainUrls(projects);
        } catch (error) {
          console.error('Error fetching projects:', error);
        }
      };

      fetchProjects();
    }
  }, [user]);

  const handleProjectSelection = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
    setSelectedProjectIds(selectedOptions);
  };

  const getFCPRecommendation = (fcp) => {
    if (!fcp) return 'No recommendation available.';
    const { quality } = evaluateFCP(fcp);
    switch (quality) {
      case 'Good':
        return 'Your FCP is absolutely fine!';
      case 'Needs Improvement':
        return [
          'Use lazy loading for images and videos.',
          'Use a fast, lightweight theme.',
          'Reduce server response times.'
        ].join('\n');
      case 'Poor':
        return [
          'Optimize images by compressing them.',
          'Minimize critical JavaScript.'
        ].join('\n');
      default:
        return '';
    }
  };
  
  const getLCPRecommendation = (lcp) => {
    if (!lcp) return 'No recommendation available.';
    const { quality } = evaluateLCP(lcp);
    
    switch (quality) {
      case 'Good':
        return 'Great job on your LCP!';
      case 'Needs Improvement':
        return [
          'Optimize fonts and remove unused CSS.',
          'Ensure text remains visible during webfont load.'
        ].join('\n');
      case 'Poor':
        return [
          'Serve images in modern formats (e.g., WebP).',
          'Preload the largest assets.',
          'Optimize the server response time for the largest contentful element.'
        ].join('\n');
      default:
        return '';
    }
  }; 
  
  const getTBTRecommendation = (tbt) => {
    if (!tbt) return 'No recommendation available.';
    const { quality } = evaluateTBT(tbt);
    
    switch (quality) {
      case 'Good':
        return 'Your TBT is good!';
      case 'Needs Improvement':
        return [
          'Avoid blocking the main thread.',
          'Use web workers for heavy computations.'
        ].join('\n');
      case 'Poor':
        return [
          'Minimize JavaScript execution time.',
          'Break long tasks into smaller, asynchronous tasks.'
        ].join('\n');
      default:
        return '';
    }
  };
  
  const getCLSRecommendation = (cls) => {
    if (!cls) return 'No recommendation available.';
    const { quality } = evaluateCLS(cls);
  
    switch (quality) {
      case 'Good':
        return 'Nice work on your CLS!';
      case 'Needs Improvement':
        return [
          'Use CSS to reserve space for content.',
          'Ensure that UI elements don’t shift during page load.'
        ].join('\n');
      case 'Poor':
        return [
          'Set size attributes for all media (images, videos).',
          'Avoid inserting ads and embeds above content.'
        ].join('\n');
      default:
        return '';
    }
  };
    
  const getSpeedIndexRecommendation = (speedIndex) => {
    if (!speedIndex) return 'No recommendation available.';
    const { quality } = evaluateSpeedIndex(speedIndex);
  
    switch (quality) {
      case 'Good':
        return 'Great job on your Speed Index!';
      case 'Needs Improvement':
        return [
          'Reduce JavaScript execution time.',
          'Defer loading of non-critical resources.'
        ].join('\n');
      case 'Poor':
        return [
          'Optimize rendering performance.',
          'Use critical CSS and preload important resources.'
        ].join('\n');
      default:
        return '';
    }
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const urlList = urls.split('\n').map(url => url.trim()).filter(url => url);
    fetchPageSpeedData(urlList);
  };

  const handleExportCSV = () => {
    try {
      const headers = 'URLs,Mobile Speed,Desktop Speed,Mobile FCP,Mobile LCP,Mobile TBT,Mobile CLS,Mobile Speed Index,Desktop FCP,Desktop LCP,Desktop TBT,Desktop CLS,Desktop Speed Index\n';
      const rows = results.map(result => 
        `${result.url},${Math.round(result.mobileSpeed)},${Math.round(result.desktopSpeed)},` +
        `${result.mobileMetrics.fcp || 'N/A'},${result.mobileMetrics.lcp || 'N/A'},` +
        `${result.mobileMetrics.tbt || 'N/A'},${result.mobileMetrics.cls || 'N/A'},` +
        `${result.mobileMetrics.speedIndex || 'N/A'},` +
        `${result.desktopMetrics.fcp || 'N/A'},${result.desktopMetrics.lcp || 'N/A'},` +
        `${result.desktopMetrics.tbt || 'N/A'},${result.desktopMetrics.cls || 'N/A'},` +
        `${result.desktopMetrics.speedIndex || 'N/A'}`
      ).join('\n');
      
      const csv = headers + rows;
      const blob = new Blob([csv], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      const date = new Date().toISOString().split('T')[0]; 
      link.download = `custom_pagespeed_report_${date}.csv`;
      link.click();
    } catch (error) {
      console.error('Error exporting CSV:', error);
    }
  };
  
  const handleExportPDF = () => {
    try {
      const doc = new jsPDF();
      
      doc.setFont("helvetica", "normal");
      doc.setFontSize(12);
      doc.setTextColor(40); 
      
      doc.setFontSize(16);
      doc.setTextColor(0); 
      doc.text("Custom PageSpeed Report", 14, 20);
      
      const date = new Date().toISOString().split('T')[0];
      doc.setFontSize(10);
      doc.text(`Date: ${date}`, 14, 30);
  
      doc.autoTable({
        head: [['URL', 'Mobile Speed', 'Desktop Speed']],
        body: results.map(result => [
          result.url, 
          Math.round(result.mobileSpeed), 
          Math.round(result.desktopSpeed)
        ]),
        theme: 'grid',
        headStyles: { fillColor: [22, 160, 133], textColor: 255 },
        styles: { cellPadding: 5, fontSize: 8 },
        margin: { top: 40 },
      });
  
      results.forEach(result => {
        const url = result.url;
        
        doc.autoTable({
          head: [[`Mobile metrics - ${url}`, 'Value']],
          body: [
            ['First Contentful Paint (FCP)', result.mobileMetrics.fcp ? `${result.mobileMetrics.fcp} s` : 'N/A'],
            ['Largest Contentful Paint (LCP)', result.mobileMetrics.lcp ? `${result.mobileMetrics.lcp} s` : 'N/A'],
            ['Total Blocking Time (TBT)', result.mobileMetrics.tbt ? `${result.mobileMetrics.tbt} ms` : 'N/A'],
            ['Cumulative Layout Shift (CLS)', result.mobileMetrics.cls ? `${result.mobileMetrics.cls}` : 'N/A'],
            ['Speed Index', result.mobileMetrics.speedIndex ? `${result.mobileMetrics.speedIndex} s` : 'N/A']
          ],
          theme: 'grid',
          headStyles: { fillColor: [22, 160, 133], textColor: 255 },
          styles: { cellPadding: 5, fontSize: 8 },
          startY: doc.autoTable.previous.finalY + 10, 
        });
        
        doc.autoTable({
          head: [[`Desktop metrics - ${url}`, 'Value']],
          body: [
            ['First Contentful Paint (FCP)', result.desktopMetrics.fcp ? `${result.desktopMetrics.fcp} s` : 'N/A'],
            ['Largest Contentful Paint (LCP)', result.desktopMetrics.lcp ? `${result.desktopMetrics.lcp} s` : 'N/A'],
            ['Total Blocking Time (TBT)', result.desktopMetrics.tbt ? `${result.desktopMetrics.tbt} ms` : 'N/A'],
            ['Cumulative Layout Shift (CLS)', result.desktopMetrics.cls ? `${result.desktopMetrics.cls}` : 'N/A'],
            ['Speed Index', result.desktopMetrics.speedIndex ? `${result.desktopMetrics.speedIndex} s` : 'N/A']
          ],
          theme: 'grid',
          headStyles: { fillColor: [22, 160, 133], textColor: 255 },
          styles: { cellPadding: 5, fontSize: 8 },
          startY: doc.autoTable.previous.finalY + 10 
        });
      });
      
      doc.save(`custom_pagespeed_report_${date}.pdf`);
    } catch (error) {
      console.error('Error exporting PDF:', error);
    }
  };
    
    const handleScheduleSubmit = async (event) => {
    event.preventDefault();
    setAutoMode(true);

    try {
      const scheduleString = (() => {
        if (scheduleType === 'specific') {
          return `Specific Date/Time at ${scheduleDetails.dateTime}`;
        } else if (scheduleType === 'weekly') {
          return `Weekly on ${scheduleDetails.dayOfWeek} at ${scheduleDetails.time}`;
        } else if (scheduleType === 'monthly') {
          return `Monthly on ${scheduleDetails.dayOfMonth} at ${scheduleDetails.time}`;
        }
        return '';
      })();

      console.log('Schedule String:', scheduleString);

      const projectNamesPromises = selectedProjectIds.map(async (projectId) => {
        const projectSnapshot = await db.collection(`users/${user.uid}/projects`).doc(projectId).get();
        return projectSnapshot.data()?.name || 'Unknown Project';
      });

      const projectNames = await Promise.all(projectNamesPromises);
      setSchedule(scheduleString); 
      setScheduleMessage(`You have scheduled auto mode for ${projectNames.join(', ')} at ${scheduleString}`);

      if (user) {
        for (const projectId of selectedProjectIds) {
          await db.collection(`users/${user.uid}/schedules`).doc(projectId).set({ 
            projectId,
            scheduleType,
            scheduleDetails
          });
        }
      }
      
      setShowScheduleModal(false);
    } catch (error) {
      console.error('Error scheduling auto mode:', error);
    }
  };

  function formatDate(date) {
    const day = date.getDate();
    const ordinalSuffix = getOrdinalSuffix(day);
    const month = date.toLocaleString('en-US', { month: 'long' });
    const year = date.getFullYear();
    const time = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
    return `${day}${ordinalSuffix} ${month} ${year}, ${time}`;
  }

  function getOrdinalSuffix(day) {
    if (day > 3 && day < 21) return 'th'; 
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }

  return (
    <div className="container mt-3 animated-container">
      <div className="header">
        <Link to="/">
          <img src="/logo.png" alt="Logo" className="logo" />
        </Link>
        <div className="auth-button">
          {user ? (
            <button onClick={handleLogout} className="logout-btn">Logout</button>
          ) : (
            <Link to="/login">
              <button className="login-btn">Login</button>
            </Link>
          )}
        </div>
      </div>
      <div>
        <div className="card shadow-sm">
          <div className="card-body">
            <center><h2 className="card-title text-center mb-4 animated-title">PageSpeed Analysis</h2></center>
            <form onSubmit={handleSubmit}>
              <div className="form-group1">
                <label htmlFor="urls">Enter website URLs (one per line)</label>
                <textarea
                  className="form-control animated-textarea"
                  id="urls"
                  value={urls}
                  onChange={(e) => setUrls(e.target.value)}
                  rows="10"
                  required={!autoMode}
                  disabled={autoMode}
                />
              </div>
              <br />
              <center>
                <button type="submit" className="glow-on-hover" disabled={autoMode}>Get Page Speed</button>
              </center>
            </form>
            {autoMode && (
              <div className="alert alert-info mt-3">
                Auto mode is active. Page Speed Insights section is disabled.
              </div>
            )}
            {isFetching && <SpinnerProgressBar value={50} />}
            {!autoMode && results.length > 0 && (
              <div className="mt-4">
                <div className="d-flex justify-content-end mb-3">
                  <DropdownButton
                    id="save-as-dropdown"
                    title="Download"
                    variant="purple"
                    className="btn-download btn-pill"
                  >
                    <Dropdown.Item onClick={handleExportCSV}>Download As CSV</Dropdown.Item>
                    <Dropdown.Item onClick={handleExportPDF}>Download As PDF</Dropdown.Item>
                  </DropdownButton>
                </div>
                {results.map((result, index) => (
                  <div key={index} className="result-card card mt-3">
                    <div className="card-body">
                      <h5 className="card-title">URL: {result.url}</h5>
                      <p>Checked On: {formatDate(new Date(result.timestamp))}</p>
                      <div className="row">
                        <div className="col-md-6 text-center">
                          <h6>Mobile Speed</h6>
                          <div style={{ width: '100px', height: '100px', margin: 'auto' }}>
                            <CircularProgressbar
                              value={result.mobileSpeed}
                              text={`${result.mobileSpeed}`}
                              styles={buildStyles({
                                pathColor: getColorAndStatus(result.mobileSpeed).color,
                                textColor: getColorAndStatus(result.mobileSpeed).color,
                              })}
                            />
                          </div>
                          <p style={{ color: getColorAndStatus(result.mobileSpeed).color }}>
                            {getColorAndStatus(result.mobileSpeed).status}
                          </p>
                          <p className="card-text">Mobile Speed: <span className="badge badge-success">{result.mobileSpeed}</span></p>
                        </div>
                        <div className="col-md-6 text-center">
                          <h6>Desktop Speed</h6>
                          <div style={{ width: '100px', height: '100px', margin: 'auto' }}>
                            <CircularProgressbar
                              value={result.desktopSpeed}
                              text={`${result.desktopSpeed}`}
                              styles={buildStyles({
                                pathColor: getColorAndStatus(result.desktopSpeed).color,
                                textColor: getColorAndStatus(result.desktopSpeed).color,
                              })}
                            />
                          </div>
                          <p style={{ color: getColorAndStatus(result.desktopSpeed).color }}>
                            {getColorAndStatus(result.desktopSpeed).status}
                          </p>
                          <p className="card-text">Desktop Speed: <span className="badge badge-success">{result.desktopSpeed}</span></p>
                        </div>
                        <div className="mt-3 core-web-vitals">
                            <h5>Core Web Vitals</h5>
                            <div className="metrics-container">
                              <div className="metrics-box">
                                <h2>Mobile Metrics</h2>
                                <ul>
                                  <li>
                                    First Contentful Paint (FCP): {result.mobileMetrics.fcp || 'N/A'}
                                    <span className="badge" style={{ color: evaluateFCP(result.mobileMetrics.fcp).color }}>
                                      {evaluateFCP(result.mobileMetrics.fcp).quality}
                                    </span>
                                    <p className="recommendation">{getFCPRecommendation(result.mobileMetrics.fcp)}</p>
                                  </li>
                                  <li>
                                    Largest Contentful Paint (LCP): {result.mobileMetrics.lcp || 'N/A'}
                                    <span className="badge" style={{ color: evaluateLCP(result.mobileMetrics.lcp).color }}>
                                      {evaluateLCP(result.mobileMetrics.lcp).quality}
                                    </span>
                                    <p className="recommendation">{getLCPRecommendation(result.mobileMetrics.lcp)}</p>
                                  </li>
                                  <li>
                                    Total Blocking Time (TBT): {result.mobileMetrics.tbt || 'N/A'}
                                    <span className="badge" style={{ color: evaluateTBT(result.mobileMetrics.tbt).color }}>
                                      {evaluateTBT(result.mobileMetrics.tbt).quality}
                                    </span>
                                    <p className="recommendation">{getTBTRecommendation(result.mobileMetrics.tbt)}</p>
                                  </li>
                                  <li>
                                    Cumulative Layout Shift (CLS): {result.mobileMetrics.cls || 'N/A'}
                                    <span className="badge" style={{ color: evaluateCLS(result.mobileMetrics.cls).color }}>
                                      {evaluateCLS(result.mobileMetrics.cls).quality}
                                    </span>
                                    <p className="recommendation">{getCLSRecommendation(result.mobileMetrics.cls)}</p>
                                  </li>
                                  <li>
                                    Speed Index: {result.mobileMetrics.speedIndex || 'N/A'}
                                    <span className="badge" style={{ color: evaluateSpeedIndex(result.mobileMetrics.speedIndex).color }}>
                                      {evaluateSpeedIndex(result.mobileMetrics.speedIndex).quality}
                                    </span>
                                    <p className="recommendation">{getSpeedIndexRecommendation(result.mobileMetrics.speedIndex)}</p>
                                  </li>
                                </ul>
                              </div>

                              <div className="metrics-box">
                                <h2>Desktop Metrics</h2>
                                <ul>
                                  <li>
                                    First Contentful Paint (FCP): {result.desktopMetrics.fcp || 'N/A'}
                                    <span className="badge" style={{ color: evaluateFCP(result.desktopMetrics.fcp).color }}>
                                      {evaluateFCP(result.desktopMetrics.fcp).quality}
                                    </span>
                                    <p className="recommendation">{getFCPRecommendation(result.desktopMetrics.fcp)}</p>
                                  </li>
                                  <li>
                                    Largest Contentful Paint (LCP): {result.desktopMetrics.lcp || 'N/A'}
                                    <span className="badge" style={{ color: evaluateLCP(result.desktopMetrics.lcp).color }}>
                                      {evaluateLCP(result.desktopMetrics.lcp).quality}
                                    </span>
                                    <p className="recommendation">{getLCPRecommendation(result.desktopMetrics.lcp)}</p>
                                  </li>
                                  <li>
                                    Total Blocking Time (TBT): {result.desktopMetrics.tbt || 'N/A'}
                                    <span className="badge" style={{ color: evaluateTBT(result.desktopMetrics.tbt).color }}>
                                      {evaluateTBT(result.desktopMetrics.tbt).quality}
                                    </span>
                                    <p className="recommendation">{getTBTRecommendation(result.desktopMetrics.tbt)}</p>
                                  </li>
                                  <li>
                                    Cumulative Layout Shift (CLS): {result.desktopMetrics.cls || 'N/A'}
                                    <span className="badge" style={{ color: evaluateCLS(result.desktopMetrics.cls).color }}>
                                      {evaluateCLS(result.desktopMetrics.cls).quality}
                                    </span>
                                    <p className="recommendation">{getCLSRecommendation(result.desktopMetrics.cls)}</p>
                                  </li>
                                  <li>
                                    Speed Index: {result.desktopMetrics.speedIndex || 'N/A'}
                                    <span className="badge" style={{ color: evaluateSpeedIndex(result.desktopMetrics.speedIndex).color }}>
                                      {evaluateSpeedIndex(result.desktopMetrics.speedIndex).quality}
                                    </span>
                                    <p className="recommendation">{getSpeedIndexRecommendation(result.desktopMetrics.speedIndex)}</p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="mt-3">
                            <center><h5>Core Web Vitals vs Bounce Rate</h5></center>
                            <CoreWebVitalsChart metricsData={results} />
                          </div>

                          </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <br />
    
      <div className="container mt-3 animated-container">
        <AdditionalFeature />
      </div>
      <div className="container mt-3 animated-container">
        <HistoricalDataAnalysis />
      </div>
      <br />
      <div>
        <center>
          <AutoMode />
          {autoMode && schedule && (
            <div className="message-box mt-3">
              Scheduled auto mode for: {schedule}
            </div>
          )}
          {scheduleMessage && (
            <div className="message-box mt-3">
              {scheduleMessage}
            </div>
          )}
        </center>
        <SpinnerProgressBar isLoading={isFetching} />
      </div>
      <Modal show={showScheduleModal} onHide={() => setShowScheduleModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Schedule Auto Mode</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleScheduleSubmit}>
            <Form.Group>
              <Form.Label>Select Projects</Form.Label>
              <Form.Control as="select" multiple value={selectedProjectIds} onChange={handleProjectSelection}>
                {mainUrls?.length > 0 ? (
                  mainUrls.map((project, index) => (
                    <option key={index} value={project.id}>{project.name}</option>
                  ))
                ) : (
                  <option value="">No Projects Available</option>
                )}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Choose Schedule Type</Form.Label>
              <Form.Control as="select" value={scheduleType} onChange={(e) => setScheduleType(e.target.value)}>
                <option value="">Select Schedule Type</option>
                <option value="specific">Specific Date/Time</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
              </Form.Control>
            </Form.Group>

            {scheduleType === 'specific' && (
              <Form.Group>
                <Form.Label>Specific Date/Time</Form.Label>
                <Form.Control type="datetime-local" value={scheduleDetails.dateTime} onChange={(e) => setScheduleDetails({ ...scheduleDetails, dateTime: e.target.value })} />
              </Form.Group>
            )}

            {scheduleType === 'weekly' && (
              <>
                <Form.Group>
                  <Form.Label>Day of the Week</Form.Label>
                  <Form.Control as="select" value={scheduleDetails.dayOfWeek} onChange={(e) => setScheduleDetails({ ...scheduleDetails, dayOfWeek: e.target.value })}>
                    <option value="0">Sunday</option>
                    <option value="1">Monday</option>
                    <option value="2">Tuesday</option>
                    <option value="3">Wednesday</option>
                    <option value="4">Thursday</option>
                    <option value="5">Friday</option>
                    <option value="6">Saturday</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Time</Form.Label>
                  <Form.Control type="time" value={scheduleDetails.time} onChange={(e) => setScheduleDetails({ ...scheduleDetails, time: e.target.value })} />
                </Form.Group>
              </>
            )}

            {scheduleType === 'monthly' && (
              <>
                <Form.Group>
                  <Form.Label>Day of the Month</Form.Label>
                  <Form.Control type="number" value={scheduleDetails.dayOfMonth} onChange={(e) => setScheduleDetails({ ...scheduleDetails, dayOfMonth: e.target.value })} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Time</Form.Label>
                  <Form.Control type="time" value={scheduleDetails.time} onChange={(e) => setScheduleDetails({ ...scheduleDetails, time: e.target.value })} />
                </Form.Group>
              </>
            )}

            <Button variant="primary" type="submit">Save Schedule</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default App;

import React from 'react';
import { Bar } from 'react-chartjs-2';

const CoreWebVitalsChart = ({ metricsData }) => {

  const toNumericValue = (metric) => {
    if (typeof metric === 'string') {
      return parseFloat(metric.replace(/[a-z]/gi, '')); 
    }
    return metric; 
  };

 
  const calculateBounceRate = (metric, type) => {
    const value = toNumericValue(metricsData[0][`${type}Metrics`][metric]); 

    if (type === 'mobile') {
      switch (metric) {
        case 'fcp':
          return value < 1.8 ? 15 : value <= 3 ? 30 : 50;
        case 'lcp':
          return value < 2.5 ? 15 : value <= 4 ? 35 : 60;
        case 'tbt':
          return value < 200 ? 15 : value <= 600 ? 30 : 50;
        case 'cls':
          return value < 0.1 ? 15 : value <= 0.25 ? 35 : 50;
        case 'speedIndex':
          return value < 3.4 ? 15 : value <= 5.8 ? 30 : 60;
        default:
          return 0;
      }
    } else if (type === 'desktop') {
      switch (metric) {
        case 'fcp':
          return value < 1.8 ? 15 : value <= 3 ? 30 : 50;
        case 'lcp':
          return value < 2.5 ? 15 : value <= 4 ? 35 : 60;
        case 'tbt':
          return value < 200 ? 15 : value <= 600 ? 30 : 50;
        case 'cls':
          return value < 0.1 ? 15 : value <= 0.25 ? 35 : 50;
        case 'speedIndex':
          return value < 3.4 ? 15 : value <= 5.8 ? 30 : 60;
        default:
          return 0;
      }
    }
  };

  const dataConfig = [
    {
      label: 'Mobile FCP',
      data: [toNumericValue(metricsData[0].mobileMetrics.fcp)],
      bounceRate: calculateBounceRate('fcp', 'mobile'),
    },
    {
      label: 'Desktop FCP',
      data: [toNumericValue(metricsData[0].desktopMetrics.fcp)],
      bounceRate: calculateBounceRate('fcp', 'desktop'),
    },
    {
      label: 'Mobile LCP',
      data: [toNumericValue(metricsData[0].mobileMetrics.lcp)],
      bounceRate: calculateBounceRate('lcp', 'mobile'),
    },
    {
      label: 'Desktop LCP',
      data: [toNumericValue(metricsData[0].desktopMetrics.lcp)],
      bounceRate: calculateBounceRate('lcp', 'desktop'),
    },
    {
      label: 'Mobile TBT',
      data: [toNumericValue(metricsData[0].mobileMetrics.tbt)],
      bounceRate: calculateBounceRate('tbt', 'mobile'),
    },
    {
      label: 'Desktop TBT',
      data: [toNumericValue(metricsData[0].desktopMetrics.tbt)],
      bounceRate: calculateBounceRate('tbt', 'desktop'),
    },
    {
      label: 'Mobile CLS',
      data: [toNumericValue(metricsData[0].mobileMetrics.cls)],
      bounceRate: calculateBounceRate('cls', 'mobile'),
    },
    {
      label: 'Desktop CLS',
      data: [toNumericValue(metricsData[0].desktopMetrics.cls)],
      bounceRate: calculateBounceRate('cls', 'desktop'),
    },
    {
      label: 'Mobile Speed Index',
      data: [toNumericValue(metricsData[0].mobileMetrics.speedIndex)],
      bounceRate: calculateBounceRate('speedIndex', 'mobile'),
    },
    {
      label: 'Desktop Speed Index',
      data: [toNumericValue(metricsData[0].desktopMetrics.speedIndex)],
      bounceRate: calculateBounceRate('speedIndex', 'desktop'),
    },
  ];

  return (
    <div className="charts-container">
      {dataConfig.map((metric, index) => (
        <div key={index} className="chart-box">
          <h3>{metric.label}</h3>
          <Bar
            data={{
              labels: [metric.label],
              datasets: [
                {
                  label: 'Value',
                  data: metric.data,
                  backgroundColor: 'rgba(75, 192, 192, 0.6)',
                },
                {
                  label: 'Bounce Rate',
                  data: [metric.bounceRate],
                  type: 'line',
                  borderColor: 'rgba(255, 0, 0, 1)',
                  fill: false,
                  tension: 0.1,
                },
              ],
            }}
            options={{
              scales: {
                y: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: 'Values',
                  },
                },
              },
              plugins: {
                legend: {
                  position: 'top',
                },
              },
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default CoreWebVitalsChart;
